import React from 'react';
import styled from 'styled-components';

import Column from '../components/column';
import Container from '../components/container';
import Checkout from '../components/checkout';
import Heading from '../components/heading';
import Layout from '../components/layout';
import Row from '../components/row';
import { Section } from '../components/section';
import Icon from '../components/icon';
import Link from '../components/link';
import logo from '../assets/icons/logo.svg';

const HeadSection = styled(Section)`
  text-align: center;
  background: ${({ theme }) => theme.colors.primary};

  svg {
    width: 100%;
    max-width: 200px;
    margin: 0 auto;
  }

  a {
    margin-top: 15px;
    color: ${({ theme }) => theme.colors.bg};
  }
`;

export default () => (
  <Layout title="Koszyk">
    <HeadSection>
      <Container>
        <Row>
          <Column>
            <Link to="/" style={{ display: 'block' }}>
              <Icon icon={logo} fill="#ffffff" />
            </Link>
            <Link to="/" style={{ display: 'block' }}>wróć do sklepu</Link>
          </Column>
        </Row>
      </Container>
    </HeadSection>
    <Section>
      <Container>
        <Row>
          <Column>
            <Heading as="h1">Twoje zakupy</Heading>
            <Checkout />
          </Column>
        </Row>
      </Container>
    </Section>
  </Layout>
);
