import * as Yup from 'yup';
import sanitize from 'sanitize-html';

Yup.addMethod(Yup.string, 'sanitize', function callback() {
  return this.transform(value => sanitize(value));
});

export default Yup.object().shape({
  email: Yup
    .string()
    .trim()
    .email('Podany adres e-mail nie jest poprawny.')
    .required('To pole nie moze być puste.'),
  firstName: Yup
    .string()
    .trim()
    .sanitize()
    .required('To pole nie moze być puste.'),
  lastName: Yup
    .string()
    .trim()
    .sanitize()
    .required('To pole nie moze być puste.'),
  street: Yup
    .string()
    .trim()
    .sanitize()
    .required('To pole nie moze być puste.'),
  streetNumber: Yup
    .string()
    .trim()
    .sanitize()
    .required('To pole nie moze być puste.'),
  localNumber: Yup
    .string()
    .trim()
    .sanitize(),
  postCode: Yup
    .string()
    .trim()
    .sanitize()
    .matches(/[0-9]{2}-[0-9]{3}/, 'Podany kod pocztowy nie jest poprawny. Podaj w formacie np. 00-000.')
    .required('To pole nie moze być puste.'),
  city: Yup
    .string()
    .trim()
    .sanitize()
    .required('To pole nie moze być puste.'),
  phoneNumber: Yup
    .string()
    .trim()
    .sanitize(),
  code: Yup
    .string()
    .trim()
    .default('')
    .sanitize(),
  consent: Yup
    .bool()
    .test(
      'consent',
      'To pole musi być zaakceptowane',
      value => value === true,
    )
    .required('To pole musi być zaakceptowane'),
  rodo: Yup
    .bool()
    .test(
      'rodo',
      'To pole musi być zaakceptowane',
      value => value === true,
    )
    .required('To pole musi być zaakceptowane'),
});
