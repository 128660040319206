import React, { PureComponent } from 'react';
import Cookies from 'js-cookie';
import {
  Field,
  Form,
  Formik,
} from 'formik';
import styled, { ThemeProvider } from 'styled-components';
import { fetch } from 'whatwg-fetch';
import qs from 'qs';

import Cart from './cart';
import Column from './column';
import Link from './link';
import Heading from './heading';
import Row from './row';
import StyledInput, { Button, Checkbox } from './styled-form';

import validationSchema from '../validation-schema/checkout';
import { apiUrl } from '../utils/get-api-url';

const endpoint = `${apiUrl}/cart`;

const fetchOptions = {
  credentials: 'include',
  mode: 'cors',
};

const getCurrentCartIdHeader = () => {
  const currentCartId = Cookies.get('__session');
  if (currentCartId) {
    return {
      'cart-id': currentCartId,
    }
  }

  return {};
}

const ErrorWrapper = styled.div`
  padding: 15px;
  margin-bottom: 30px;
  text-align: center;
  color: ${({ theme }) => theme.colors.bg};
  background: #ED4337;
`;

const FormContent = ({ isButtonDisabled }) => (
  <ThemeProvider theme={{ light: false }}>
    <>
      <Row>
        <Column max="true">
          <Field type="e-mail" name="email" label="adres e-mail *" component={StyledInput} />
        </Column>
      </Row>
      <Row>
        <Column>
          <Field type="text" name="firstName" label="imię *" component={StyledInput} />
        </Column>
        <Column>
          <Field type="text" name="lastName" label="nazwisko *" component={StyledInput} />
        </Column>
      </Row>
      <Row>
        <Column>
          <Field type="text" name="street" label="ulica *" component={StyledInput} />
        </Column>
        <Column>
          <Row>
          <Column>
            <Field type="text" name="streetNumber" label="numer *" component={StyledInput} />
          </Column>
          <Column>
            <Field type="text" name="localNumber" label="lokal" component={StyledInput} />
          </Column>
          </Row>
        </Column>
      </Row>
      <Row>
        <Column>
          <Field type="text" name="postCode" label="kod pocztowy *" component={StyledInput} />
        </Column>
        <Column style={{ flex: 2 }}>
          <Field type="text" name="city" label="miejscowość *" component={StyledInput} />
        </Column>
      </Row>
      <Row>
        <Column>
          <Field type="text" name="phoneNumber" label="numer telefonu" component={StyledInput} />
        </Column>
      </Row>
      <Row>
        <Column>
          <Field
            name="consent"
            component={Checkbox}
            label={() => (
              <span>
                {'* Zapoznałem się z '}
                <Link to="/regulamin-sklepu">regulaminem</Link>
                {' i '}
                <Link to="/polityka-prywatnosci">polityką prywatności</Link>
                {'. Akceptuję postanowienia tych dokumentów.'}
              </span>
            )}
          />
          <Field
            name="rodo"
            component={Checkbox}
            label={() => '* Wiem, że dane osobowe będą przetwarzane w celu realizacji zamówienia zgodnie z informacjami zawartymi w polityce prywatności.'}
          />
          <Field
            name="newsletter"
            component={Checkbox}
            label={() => 'Zapisz mnie do newslettera'}
          />
        </Column>
      </Row>
      <Row>
        <Column>
          <Button type="submit" disabled={isButtonDisabled} style={{ width: '100%' }}>
            Zamawiam i płacę
          </Button>
        </Column>
      </Row>
    </>
  </ThemeProvider>
);

class Checkout extends PureComponent {
  state = {
    isLoading: true,
    cart: {
      items: [],
      totalPrice: 0,
      shipmentPrice: 0,
    },
  };

  componentDidMount() {
    this.getCart();
  }

  getCart = async (code) => {
    try {
      const currentCartId = Cookies.get('__session');

      this.setState(state => ({ ...state, isLoading: true }), async () => {
        const response = await fetch(`${endpoint}?${qs.stringify({ code })}`, {
          ...fetchOptions,
          headers: {
            ...getCurrentCartIdHeader(),
          }
        });
        
        const cart = await response.json();

        if (currentCartId !== cart.id) {
          Cookies.set('__session', cart.id, { expires: 180 });
        }

        this.setState(state => ({
          ...state,
          cart,
          error: '',
          isLoading: false,
        }));
      });
    } catch (err) {
      console.log(err);
    }
  }

  completeCheckout = async (values, { setSubmitting }) => {
    const { code = '' } = this.state.cart;

    const response = await fetch(`${endpoint}/checkout`, {
      ...fetchOptions,
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        ...getCurrentCartIdHeader(),
      },
      body: qs.stringify({
        ...values,
        code,
      }),
    });

    const responseJson = await response.json();
    const {
      cart,
      error,
      message,
      redirectUrl,
    } = responseJson;

    setSubmitting(false);

    if (error) {
      this.setState(state => ({ ...state, cart, error: message }));
      return;
    }

    Cookies.remove('__session');

    this.setState(state => ({
      ...state,
      error: '',
      redirectUrl,
    }));
  }

  isButtonDisabled(isSubmitting) {
    const {
      cart: {
        items,
        totalPrice,
      },
    } = this.state;

    if (isSubmitting) {
      return true;
    }

    return items.length > 0 && totalPrice === 0;
  }

  renderSuccessfulMessage = url => (
    <div style={{ textAlign: 'center' }}>
      <Heading color="third">Twoje zamówienie zostało złożone!</Heading>
      <Button as="a" href={url}>Opłać zamówienie</Button>
    </div>
  );

  renderForm(isSubmitting) {
    const {
      cart: {
        items = [],
      },
      redirectUrl
    } = this.state;

    if (items.length === 0) return null;

    const content = redirectUrl
      ? this.renderSuccessfulMessage(redirectUrl)
      : <FormContent isButtonDisabled={this.isButtonDisabled(isSubmitting)} />;

    return (
      <>
        <Column bg="fifth" offset="1" style={{ padding: '30px' }}>
          { content }
        </Column>
      </>
    );
  }

  render() {
    const {
      cart,
      error,
      isLoading,
      redirectUrl,
    } = this.state;

    return (
      <Formik
        initialValues={{
          email: '',
          firstName: '',
          lastName: '',
          street: '',
          streetNumber: '',
          localNumber: '',
          postCode: '',
          city: '',
          phoneNumber: '',
          code: '',
          consent: false,
          rodo: false,
          newsletter: false,
        }}
        validateOnBlur={false}
        validationSchema={validationSchema}
        onSubmit={this.completeCheckout}
      >
        {({ isSubmitting }) => (
          <Row as={Form}>
            <Column>
              { error && <ErrorWrapper>{ error }</ErrorWrapper> }
              {
                !redirectUrl && (
                  <Cart
                    {...cart}
                    isLoading={isLoading}
                    endpoint={endpoint}
                    getCart={this.getCart}
                  />
                )
              }
            </Column>
            { this.renderForm(isSubmitting) }
          </Row>
        )}
      </Formik>
    );
  }
}

export default Checkout;
