import React, { PureComponent } from 'react';
import Cookies from 'js-cookie';
import Loader from 'react-loader-spinner';
import styled from 'styled-components';
import { fetch } from 'whatwg-fetch';

// import Cta from './cta';
import Icon from './icon';

import X from '../assets/icons/x.svg';
import { colors } from '../configs/theme';

const fetchOptions = {
  credentials: 'include',
  mode: 'cors',
};

const getCurrentCartIdHeader = () => {
  const currentCartId = Cookies.get('__session');
  if (currentCartId) {
    return {
      'cart-id': currentCartId,
    }
  }

  return {};
}

const Item = styled.div`
  display: flex;
  padding: 30px 0;
  align-items: center;
  border-top: 1px solid ${({ theme }) => theme.colors.secondary};
`;

const Total = styled(Item)`
  align-items: flex-start;
  margin-bottom: 30px;
`;

const TotalPrice = styled.div`
  font-size: 20px;
`;

const TotalPricePice = styled.div`
  font-size: 16px;
`;

const ItemColumn = styled.div`
  flex: 1;
  text-align: ${({ align = 'left' }) => align};
`;

const BasicButton = styled.button`
  margin: 0;
  padding: 0;
  border: 0;
  cursor: pointer;
  outline: 0;

`;

const Button = styled(BasicButton)`
  background: transparent;
  transition: transform .2s;

  &:disabled {
    cursor: auto;
    opacity: 0;
  }

  &:hover, &:focus {
    transform: scale(1.2);
  }
`;

const RemoveBtn = styled(Button)`
  width: 15px;
`;

const QuantityStyled = styled(ItemColumn)`
  display: flex;
  flex: 2;
  align-items: center;
  justify-content: center;

  button {
    line-height: 24px;
    padding: 0 10px;
    text-decoration: none;
    font-size: 21px;
    color: ${({ theme }) => theme.colors.primary};
  }

  span {
    display: inline-block;
    line-height: 24px;
    padding: 0 12.5px;
    color: ${({ theme }) => theme.colors.third};
    border: ${({ noBorder, theme }) => noBorder ? "none" : `1px solid ${theme.colors.secondary}`};
  }
`;

const Name = styled(ItemColumn)`
  flex: 2;

  div:first-child {
    position: relative;
    font-weight: 500;
    opacity: ${({ available }) => (available ? '1' : '0.5')};

    &:after {
      content: '';
      display: ${({ available }) => (available ? 'none' : 'block')};
      position: absolute;
      top: calc(50% - 1px);
      left: 0;
      width: 100%;
      height: 2px;
      background: ${({ theme }) => theme.colors.primary};
    }
  }

  div + div {
    display: ${({ available }) => (available ? 'none' : 'block')};
    font-size: 14px;
  }
`;

const PromoCode = styled.input`
  line-height: 24px;
  padding: 0 5px;
  outline: 0;
  color: ${({ theme }) => theme.colors.third};
  border: 1px solid ${({ theme }) => theme.colors.secondary};

  &:focus {
    border: 1px solid ${({ theme }) => theme.colors.primary};
  }
`;

const PromoButton = styled(BasicButton)`
  padding: 0 10px;
  line-height: 26px;
  letter-spacing: 0.1em;
  color: ${({ theme }) => theme.colors.bg};
  background: ${({ theme }) => theme.colors.secondary};
  
  &:hover, &:focus {
    background: ${({ theme }) => theme.colors.primary};
  }
`;

const Quantity = ({ qty = 1, max = qty + 1, onClick }) => (
  <QuantityStyled align="center" noBorder={max === qty}>
    <Button type="button" onClick={onClick('decrease')} value="-" disabled={qty === 1}>-</Button>
    <span>{qty}</span>
    <Button type="button" onClick={onClick('add')} value="+" disabled={max === qty}>+</Button>
  </QuantityStyled>
);

const Price = ({ currency = 'PLN', children, discount, ...rest }) => (
  <div {...rest}>
    { children }
    { ` ${currency}` }
  </div>
);

const StyledPrice = styled(Price)`
  text-decoration: ${({ discount }) => discount ? 'line-through' : 'none'};
`

const Remove = ({ category = '', onClick }) => (
  <ItemColumn align="right">
    <RemoveBtn type="button" onClick={onClick('delete')} disabled={category === 'shipment'}>
      <Icon icon={X} width="15" height="15" fill={colors.primary} />
    </RemoveBtn>
  </ItemColumn>
);

class Cart extends PureComponent {
  static defaultProps = {
    items: [],
    totalPrice: 0,
    shipmentPrice: 0,
    getCart: () => {},
  }

  promoCodeInput;

  state = {
    promoCode: '',
  }

  handleItemQuantity = id => type => async (event) => {
    event.preventDefault();

    const { endpoint, getCart } = this.props;
    const { promoCode } = this.state;

    try {
      await fetch(`${endpoint}/item/${id}/${type}`, {
        ...fetchOptions,
        headers: {
          ...getCurrentCartIdHeader(),
        }
      });
      getCart(promoCode);
    } catch (err) {
      console.log(err);
    }
  }

  applyPromoCode = (e) => {
    e.preventDefault();

    if (this.promoCodeInput) {
      const code = this.promoCodeInput.value.trim();

      if (code !== this.state.promoCode) {
        this.setState({
          ...this.state,
          promoCode: code,
        }, () => this.props.getCart(this.state.promoCode));
      }
    }
  }

  renderItem(item) {
    return (
      <Item available={item.available} key={item.id}>
        <Name available={item.available}>
          <div>{item.name}</div>
          <div>produkt niedostępny</div>
        </Name>
        <Quantity qty={item.quantity} onClick={this.handleItemQuantity(item.id)} max={item.maxQuantity} />
        <ItemColumn align="center">
          {
            item.priceWithoutDiscount !== item.price && 
            <StyledPrice discount="true">{item.priceWithoutDiscount.toFixed(2)}</StyledPrice>
          }
          <StyledPrice>{item.price.toFixed(2)}</StyledPrice>
        </ItemColumn>
        <Remove category={item.category} onClick={this.handleItemQuantity(item.id)}>{item.price}</Remove>
      </Item>
    );
  }

  renderItems() {
    const { discountValue, items, totalPrice, shipmentPrice } = this.props;
    const { promoCode } = this.state;
    return (
      <>
        { items.map(item => this.renderItem(item)) }
        <Item key="code" bg="fifth">
          <ItemColumn>
            kod promocyjny
          </ItemColumn>
          <ItemColumn  style={{ flex: 2, textAlign: 'right' }}>
            <PromoCode type="text" name="code" defaultValue={promoCode} ref={i => this.promoCodeInput = i} />
            <PromoButton type="button" onClick={this.applyPromoCode}>zastosuj</PromoButton>
          </ItemColumn>
        </Item>
        <Total key="total">
          <ItemColumn align="right">
            <TotalPrice>
              { `DO ZAPŁATY:` }
              <br/>
              {`${(totalPrice + shipmentPrice).toFixed(2)} PLN`}
              <br />
              <br />
              <TotalPricePice>
                {`Dostawa: ${shipmentPrice.toFixed(2)} PLN`}
              </TotalPricePice>
              {
                discountValue > 0 && (
                  <TotalPricePice>
                    {`Rabat: ${discountValue.toFixed(2)} PLN`}
                  </TotalPricePice>
                )
              }
            </TotalPrice>
          </ItemColumn>
        </Total>
      </>
    );
  }

  render() {
    const { items, isLoading } = this.props;

    if (isLoading) {
      return (
        <div style={{ display: 'flex', alignSelf: 'center' }}>
          <Loader
            type="Bars"
            color={colors.primary}
            width={80}
            height={80}
          />
        </div>
      );
    }

    if (items.length === 0) return (<div>Twój koszyk jest pusty</div>);

    return (
      <div>
        { this.renderItems() }
      </div>
    );
  }
}

export default Cart;
